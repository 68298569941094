import React from 'react'
import ReactDOM from 'react-dom'

import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import './styles/index.css'
import './styles/animate.css'
import 'react-loading-skeleton/dist/skeleton.css'
import store from './store'
import Root from './routes/Root'

import '../node_modules/react-smartbanner/dist/main.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { onServiceWorkerUpdate } from '@3m1/service-worker-updater'
// import register from './legacyServiceWorkerDoNotDelete'
ReactDOM.render(<Root store={store} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// register()
reportWebVitals()
serviceWorkerRegistration.register({
    onUpdate: onServiceWorkerUpdate,
})
