import { RESET, CLEAR_RESET_ERROR } from './constants/actionTypes'

const initialState = {
    fetching: false,
    fetched: false,
    onError: false,
    error: {},
    resp: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET.pending: {
            return {
                ...state,
                fetching: true,
                fetched: false,
                onError: false,
                error: {},
            }
        }
        case RESET.fulfilled: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: {},
                resp: action.payload.data,
            }
        }
        case RESET.rejected: {
            return {
                ...state,
                fetching: false,
                fetched: false,
                onError: true,
                error: action.payload,
            }
        }
        case CLEAR_RESET_ERROR.default: {
            return {
                ...state,
                fetched: false,
                onError: false,
                fetching: false,
                error: {},
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default reducer
