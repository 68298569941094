import { CARD, CLEAR_CARD_ERROR } from './constants/actionTypes'
import { SIGN_OUT } from '../../authentication/constants/actionTypes'

const initialState = {
    getCardPending: false,
    getCardError: null,
    userCard: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CARD.pending: {
            return {
                ...state,
                getCardPending: true,
                getCardError: null,
            }
        }
        case CARD.fulfilled: {
            return {
                ...state,
                getCardPending: false,
                getCardError: null,
                userCard: action.payload,
            }
        }
        case CARD.rejected: {
            return {
                ...state,
                getCardPending: false,
                getCardError: action.payload,
            }
        }
        case SIGN_OUT.default: {
            return { ...initialState }
        }
        case CLEAR_CARD_ERROR.default: {
            return {
                ...state,
                getCardError: null,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default reducer
