import { REGISTER, CLEAR_REGISTER_ERROR } from './constants/actionTypes'
import { SIGN_OUT } from './constants/actionTypes'

const initialState = {
    fetching: false,
    fetched: false,
    onError: false,
    error: {},
    resp: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER.pending: {
            return {
                ...state,
                fetching: true,
                fetched: false,
                onError: false,
                error: {},
            }
        }
        case REGISTER.fulfilled: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: {},
                resp: action.payload.response,
            }
        }
        case REGISTER.rejected: {
            return {
                ...state,
                fetching: false,
                fetched: false,
                onError: true,
                error: action.payload,
            }
        }
        case CLEAR_REGISTER_ERROR.default: {
            return {
                ...state,
                fetched: false,
                onError: false,
                fetching: false,
                error: {},
            }
        }
        case SIGN_OUT.default: {
            return { ...initialState }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default reducer
