import React from 'react'

import App from './main'

// import SmartBanner from 'react-smartbanner'

import getStore from '../store'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import ErrorBoundary from '../containers/ErrorBoundary'
import { ThemeProvider } from 'styled-components'
import theme from '../components/theme'
export const { store, persistor } = getStore()

const Root = () => (
    <ErrorBoundary>
        {/* <SmartBanner
            title={'Coinprofile'}
            daysHidden={0}
            daysReminder={0}
            position={'top'}
        /> */}
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </ErrorBoundary>
)

export default Root
