import { combineReducers } from 'redux'
import user from '../components/authentication/User.reducer'
import profile from '../components/page/profile.reducer'
import confirm from '../components/authentication/Confirm.reducer'
import change from '../components/authentication/ChangePassword.reducer'
import reset from '../components/authentication/ResetPassword.reducer'
import update from '../components/oldDashboard/profile/update.reducer'
import register from '../components/authentication/Register.reducer'
import twofactor from '../components/oldDashboard/settings/Twofactor.reducer'
import card from '../components/newDashboard/Cards/Card.reducer'

export default combineReducers({
    twofactor,
    user,
    register,
    change,
    reset,
    update,
    confirm,
    profile,
    card,
})
