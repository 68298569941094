import React, { ReactNode } from 'react'
import { Container } from './elements'

interface ModalProp {
    loading?: boolean
    onCancel?: (e: any) => void
    children: ReactNode
    backgroundColor?: string
    style?: Object
}
const Modal = ({
    loading,
    onCancel,
    children,
    backgroundColor,
    style,
}: ModalProp) => {
    const closeModal = (e: any) => {
        e.stopPropagation()

        if (onCancel && !loading) {
            onCancel(e)
        }
    }
    return (
        <Container
            onClick={closeModal}
            backgroundColor={backgroundColor}
            style={style}
        >
            {children}
        </Container>
    )
}

export default Modal
