import {
    SIGN_OUT,
    GET_USER,
    REGISTER,
    TWO_FACTOR,
    UPDATE_USER,
    UPDATE_TOKEN,
    CREATE_USERNAME,
    CLEAR_USER_ERROR,
    GET_USER_DETAILS,
} from './constants/actionTypes'

const initialState = {
    fetching: false,
    fetched: false,
    onError: false,
    error: {},
    resp: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER.pending: {
            return {
                ...state,
                fetching: true,
                fetched: false,
                onError: false,
                error: {},
            }
        }
        case UPDATE_USER.fulfilled: {
            return {
                ...state,
                resp: { ...state.resp, data: action.payload.data.data },
            }
        }

        case REGISTER.fulfilled:
        case GET_USER.fulfilled: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: {},
                resp: action.payload.response,
            }
        }
        case CREATE_USERNAME.fulfilled:
        case GET_USER_DETAILS.fulfilled: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: {},
                resp: { ...state.resp, data: action.payload.data.data },
            }
        }
        case UPDATE_TOKEN.default: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: {},
                resp: { ...state.resp, ...action.payload },
            }
        }
        case TWO_FACTOR.fulfilled: {
            return {
                ...state,
                resp: {
                    ...state.resp,
                    data:
                        action.payload.data.data &&
                        action.payload.data.data.enrolled
                            ? { ...state.resp.data, mfa: { enrolled: true } }
                            : { ...state.resp.data, mfa: null },
                },
            }
        }
        case CREATE_USERNAME.rejected:
        case GET_USER_DETAILS.rejected:
        case GET_USER.rejected: {
            return {
                ...state,
                fetching: false,
                fetched: false,
                onError: true,
                error: action.payload,
            }
        }
        case CLEAR_USER_ERROR.default: {
            return {
                ...state,
                fetched: false,
                onError: false,
                fetching: false,
                error: {},
            }
        }
        case SIGN_OUT.default: {
            return { ...initialState }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default reducer
