import React, { useEffect, Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NewPWAUpdates from '../../components/newDashboard/Home/NewPWAUpdates'
import Crypto from 'crypto'

const Auth = lazy(() => import('../../containers/Authentication'))
const Dashboard = lazy(() => import('../../containers/Dashboard'))
const SendFlow = lazy(() => import('../../containers/Remittance'))
const KYC = lazy(() => import('../../containers/KYC'))
const TransactionSuccess = lazy(() =>
    import('../../components/sendflow/forms/TransactionSuccess')
)
const TransferSuccess = lazy(() =>
    import('../../components/sendflow/forms/TransferSuccess')
)
const ConversionSuccess = lazy(() =>
    import('../../components/sendflow/convert/ConversionSuccess')
)
const Profile = lazy(() => import('../../containers/Profile'))

const VoucherTopSuccess = lazy(() =>
    import('../../components/sendflow/voucher/success')
)

const MainRoutes = () => {
    const { resp } = useSelector(({ user }) => user)
    const dashboardRoutes =
        '/(|home|settings|transactions|wallets|cards|cards-verify|cards-create|cards-bvn|cards-address)'
    const authRoutes =
        '/(register|verification-failed|login|verify|success|create-username|reset|verify-email|reset-password)'
    const sendFlowRoutes = '/(amount|email|recipient|review|pay|confirm)'

    useEffect(() => {
        const { data } = resp
        if (data?.username) {
            let dataLayer = window?.dataLayer || []
            const hash = Crypto.createHmac(
                'sha256',
                process.env?.REACT_APP_INTERCOM_SECRET
            )
                .update(data?.username)
                .digest('hex')

            dataLayer.push({
                event: 'user',
                loggedIn: true,
                email: data?.email,
                username: data?.username,
                createdAt: data.createdAt,
                name: data?.fullname || '',
                photo: data?.photo,
                userHash: hash,
                customLauncherSelector: '#intercomlauncher',
            })
        }
    }, [resp])

    const Loader = () => <div></div>
    return (
        <>
            <NewPWAUpdates />
            <Switch>
                <Suspense fallback={<Loader />}>
                    <Route
                        exact
                        path="/transaction/success/:id"
                        component={TransactionSuccess}
                    />
                    <Route
                        exact
                        path="/transfer/success"
                        component={TransferSuccess}
                    />
                    <Route
                        exact
                        path="/conversion/success"
                        component={ConversionSuccess}
                    />
                    <Route
                        exact
                        path="/voucher/success"
                        component={VoucherTopSuccess}
                    />
                    <Route exact path="/profile/@:id" component={Profile} />
                    <Route path={authRoutes} component={Auth} />
                    <Route exact path={dashboardRoutes} component={Dashboard} />
                    <Route exact path={sendFlowRoutes} component={SendFlow} />
                    <Route path="/kyc" component={KYC} />
                </Suspense>
            </Switch>
        </>
    )
}
export default MainRoutes
