import types from '../../../actionTypes'

export const RESET = types('RESET')
export const CHANGE = types('CHANGE')
export const CONFIRM = types('CONFIRM')
export const GET_USER = types('GET_USER')
export const REGISTER = types('REGISTER')
export const SIGN_OUT = types('SIGN_OUT')
export const TWO_FACTOR = types('TWO_FACTOR')
export const UPDATE_USER = types('UPDATE_USER')
export const UPDATE_TOKEN = types('UPDATE_TOKEN')
export const CREATE_USERNAME = types('CREATE_USERNAME')
export const GET_USER_DETAILS = types('GET_USER_DETAILS')
export const CLEAR_USER_ERROR = types('CLEAR_USER_ERROR')
export const CLEAR_RESET_ERROR = types('CLEAR_RESET_ERROR')
export const CLEAR_CHANGE_ERROR = types('CLEAR_CHANGE_ERROR')
export const CLEAR_REGISTER_ERROR = types('CLEAR_REGISTER_ERROR')
