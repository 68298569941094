import { CHANGE, CLEAR_CHANGE_ERROR } from './constants/actionTypes'

const initialState = {
    fetching: false,
    fetched: false,
    onError: false,
    error: {},
    resp: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE.pending: {
            return {
                ...state,
                fetching: true,
                fetched: false,
                onError: false,
                error: {},
            }
        }
        case CHANGE.fulfilled: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: {},
                resp: action.payload,
            }
        }
        case CHANGE.rejected: {
            return {
                ...state,
                fetching: false,
                fetched: false,
                onError: true,
                error: action.payload,
            }
        }
        case CLEAR_CHANGE_ERROR.default: {
            return {
                ...state,
                fetched: false,
                onError: false,
                fetching: false,
                error: {},
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default reducer
