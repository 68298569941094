export const colors = ({ theme, color = "black", variation }) => {
  const composedColor = variation
    ? theme.colors[color][variation]
    : theme.colors[color].default;
  return `
    color:${composedColor};
    `;
};

export const fontSizes = ({ theme, size = 1 }) => {
  return `
    font-size:${theme.fontSizes[size]};
    `;
};

export const fontWeight = ({ theme, thickness = 4 }) => {
  return `
    font-weight:${theme.thickness[thickness]};
    `;
};
