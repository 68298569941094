import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

const outlinedButtton = ({ outlined, alert, activate }) =>
    outlined &&
    css`
        background-color: transparent;
        color: ${alert ? '#D24339' : activate ? '#018950' : '#000000'};
        border-color: ${alert ? '#D24339' : activate ? '#018950' : '#000000'};

        &:hover {
            color: #ffffff;
            transform: translateY(-0.7px);
            background-color: ${alert
                ? 'rgba(210, 67, 57, 0.6)'
                : activate
                ? 'rgba(1, 137, 80, 0.8)'
                : 'rgba(0, 0, 0, 0.8)'};
        }
        &:active {
            color: #ffffff;
            transform: translateY(-0.7px);
            background-color: ${alert
                ? 'rgba(210, 67, 57, 0.8)'
                : activate
                ? 'rgba(1, 137, 80, 0.8)'
                : 'rgba(0, 0, 0, 0.8)'};
        }
        &:disabled {
            opacity: 0.3 !important;
            background-color: #ffffff;
            &:hover {
                opacity: 0.3 !important;
                color: ${alert ? '#D24339' : activate ? '#018950' : '#000000'};
                transform: translateY(0px);
                background-color: #ffffff;
            }
        }
    `
const wideButtton = ({ wide }) =>
    wide &&
    css`
        padding: 11px 0;
        width: 100%;
    `

export const textButton = ({ text }) =>
    text &&
    css`
        border: none;
        color: #000000;
        padding: 0;
        background-color: #ffffff;
        text-decoration: underline;
        &:hover {
            border: none;
            background-color: #ffffff;
            color: #000000;
            transform: translateY(-1px);
        }
        &:active {
            border: none;
            background-color: #ffffff;
            color: #000000;
            transform: translateY(-1px);
        }
    `
export const GoBack = styled.div`
    font-family: 'Mabry Pro', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    display: flex;
    align-items: center;
    height: 28px;
    svg {
        margin-right: 10px;
        display: block;
    }
    @media (min-width: 769px) {
        display: none;
    }
`
export const Container = styled.nav`
    display: flex;
    max-width: 947px;
    min-height: 65px;
    padding: 0 14px;
    width: 100%;
    margin: ${({ sendFlow }) => (sendFlow ? '50px' : '0px')} auto 0 auto;
    display: flex;
    z-index: 3;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: ${({ sendFlow }) =>
        sendFlow ? 'transparent' : '#ffffff'};
    border-bottom: ${({ sendFlow }) => (sendFlow ? '0px' : '1px')} solid #000000;
    transition: all 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: ${({ expand }) => (expand ? '0.42s' : '0.1s')};
    .menu-cont-large {
        display: flex;
    }
    .mobile-authentication-btn-container {
        display: none;
    }
    .menu-cont-small {
        display: none;
        /* z-index: 1; */
    }
    .nav-logo {
        width: 130px;
        height: auto;
        display: block;
        /* z-index: 1; */
    }
    .nav-logo svg {
        width: 115px;
        height: auto;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 20px;
        position: fixed;
        margin-top: 0;
        padding: 20px 10px;
        background-color: ${({ sendFlow }) =>
            sendFlow ? '#f2eddc' : '#ffffff'};
        border-bottom: ${({ sendFlow }) => (sendFlow ? '0px' : '1px')} solid
            ${({ expand }) => (expand ? '#000000' : '#000000')};
        .menu-cont-large {
            display: none;
        }
        .hidden {
            display: none;
        }
        .menu-cont-small {
            display: flex;
        }
        .show-center {
            /* position:absolute; */
            position: absolute;
            left: 0;
            right: 0;
            top: 58px;
            padding-top: 42px;
            padding-bottom: 10px;
            background-color: #f2eddc;
            .rc-steps {
                width: 100% !important;
                margin-left: -20px;
            }
            .rc-steps-item-content {
                display: none;
            }
        }
    }
`
export const Main = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    max-width: 1140px;
`
export const Button = styled.button`
    padding: 10px 27px;
    color: #ffffff;
    border-radius: 5px;
    background: #000000;
    border: 1px solid #000000;
    transition: all 0.25s ease-in-out;
    font-size: 1.1rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    text-decoration: none;
    font-family: 'Mabry Pro', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
    &:active {
        background: #000000;
    }
    :disabled {
        opacity: 0.3;
        background: rgba(0, 0, 0, 1);
    }
    ${wideButtton};
    ${outlinedButtton};
    ${textButton};
`

export const LinkButton = styled(Link)`
    padding: 10px 27px;
    color: #ffffff;
    border-radius: 5px;
    background: #000000;
    border: 1px solid #000000;
    margin-left: 32px;
    transition: all 0.25s ease-in-out;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    text-decoration: none;
    font-family: 'Mabry Pro', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
    &:active {
        background: #000000;
    }
    ${wideButtton};
    ${outlinedButtton};
    ${textButton};
`

export const BurgerButton = styled.div`
    background-color: transparent;
    border-radius: 2px;
    width: 40px;
    height: ${({ expand }) => (expand ? '28px' : '16px')};
    padding: 0 6px;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    display: flex;
    position: relative;
    font-family: 'Inter';

    .bar {
        width: 100%;
        background: #000000;
        border: 1px solid #000000;
        transition: transform ${({ expand }) => (expand ? '0.57s ' : '0.8s')}
            cubic-bezier(0.4, 0.01, 0.165, 0.99);
        border-radius: 3px;
        position: absolute;
        width: 80%;
    }
    .bar:nth-child(2) {
        transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        opacity: ${({ expand }) => (expand ? '0 ' : '1')};
        transition-delay: ${({ expand }) => (expand ? '0s' : '.2s')};
    }
    .bar:nth-child(1) {
        top: 1px;
        transform: ${({ expand }) =>
            expand
                ? 'rotate(45deg) translate(39%,7px)'
                : 'rotate(0deg) translate(0,0)'};
    }
    .bar:nth-child(3) {
        transform: ${({ expand }) =>
            expand
                ? 'rotate(-45deg)translate(30%,-6px)'
                : 'rotate(0deg) translate(0,0)'};
        bottom: 1px;
    }
`

export const Menu = styled.div`
    position: absolute;
    top: 74px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: ${({ sendFlow }) => (sendFlow ? '0px' : '1px')} solid #000000;
    padding-top: ${({ expand }) => (expand ? '15px' : '0')};
    padding-bottom: ${({ expand }) => (expand ? '25px' : '0')};
    transition: all 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: ${({ expand }) => (expand ? '0.42s' : '0.32s')};
    max-height: ${({ expand }) => (expand ? '250px' : '0px')};
    overflow: hidden;
    z-index: 1;
    a {
        margin-top: 5px;
        margin-left: 0;
        transform: ${({ expand }) =>
            expand
                ? 'scale(1) translateY(0px)'
                : 'scale(1.11) translateY(-65px)'};
        opacity: ${({ expand }) => (expand ? '1' : '0')};
        transition: transform ${({ expand }) => (expand ? '0.5s' : '0.27s')}
                cubic-bezier(0.4, 0.01, 0.165, 0.99),
            opacity ${({ expand }) => (expand ? '0s' : '0s')}
                cubic-bezier(0.4, 0.01, 0.165, 0.99);
    }
    /* a:nth-child(4) {
        transition-delay: ${({ expand }) => (expand ? '0.78s' : '0.26s')};
    }

    a:nth-child(3) {
        transition-delay: ${({ expand }) => (expand ? '0.66s' : '0.34s')};
    } */

    a:nth-child(2) {
        transition-delay: ${({ expand }) => (expand ? '0.50s' : '0.42s')};
    }
    a:nth-child(1) {
        transition-delay: ${({ expand }) => (expand ? '0.42s' : '0.46s')};
    }
    @media (min-width: 769px) {
        display: none;
    }
`

export const ContainerLogo = styled.nav`
    display: flex;
    max-width: 300px;
    width: fit-content;
    justify-content: space-between;
    align-items: center;
    ${({ lean }) =>
        lean &&
        css`
            width: auto;
            max-width: auto;
        `}
    @media (max-width: 767px) {
        max-width: 285px;
    }
    ${({ sendFlow }) =>
        sendFlow &&
        css`
            @media (max-width: 768px) {
                display: none;
            }
        `}
`
