import styled, { keyframes, css } from 'styled-components'
import { colors, fontSizes, fontWeight } from './mixins'
import Select from 'react-dropdown-select'
import { Button as DefaultButton } from '../../header/elements'

const fadeIn = keyframes`
  from {
    opacity:0;
  }

  to {
   opacity:1;
  }
`
export const Layout = styled.div`
    background-color: ${({ theme }) => theme.colors.orange[3]};
    width: ${({ theme }) => theme.sizing.flexible};
    display: flex;
    justify-content: center;
    height: 100%;
    font-family: 'Mabry Pro', sans-serif;
    padding-bottom: 50px;
    overflow-y: hidden;
    .content {
        max-width: ${({ theme }) => theme.sizing.large};
        width: ${({ theme }) => theme.sizing.flexible};
        min-height: ${({ theme }) => theme.sizing.flexible};
        .main {
            display: flex;
            padding: 0 10px;
            z-index: 2;
            position: relative;
            height: calc(100% - 75px);
        }
        .main_detail {
            width: 100%;
            max-width: calc(100% - 165px);
        }
        .main__view {
            width: 100%;
            border-radius: 5px;
            box-shadow: none;
            background-color: #ffffff;
            border: 1px solid #000000;
            padding: 45px;
            margin-bottom: 50px;
            height: 100%;
            overflow-y: scroll;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none;
            ::-webkit-scrollbar {
                display: none;
            }
        }
    }
    @media (max-width: 988px) {
        .main__view {
            padding: 20px !important;
        }
    }
    @media (max-width: 768px) {
        padding-bottom: 0;
        .main {
            padding: 70px 0 0 0 !important;
            height: 100% !important;
        }
        .main_detail {
            max-width: 100% !important;
            overflow: hidden;
        }
        .main__view {
            border-radius: 0px !important;
            border: none !important;
            padding: 30px 15px !important;
            min-height: calc(100vh - 75px) !important;
            margin-bottom: 0 !important;
            padding-bottom: 100px !important;
        }
    }
`

export const Nav = styled.nav`
    min-width: ${({ theme }) => theme.sizing.small};

    .nav-menu-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: -webkit-fill-available;
        padding-bottom: 60.5px;
        height: 100%;
    }
    a {
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: 'Mabry Pro', sans-serif;
        transition: all 100ms ease-in-out;
        position: relative;
        .nav--img,
        .nav--img__active {
            width: 20.4px;
            height: 21.5px;
            object-fit: contain;
            display: block;
            transition: all 200ms ease-in-out;
            margin-right: 17px;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .nav--img__active {
            display: none;
            transition: all 200ms ease-in-out;
        }
        .title {
            opacity: 0.8;
            font-family: 'Mabry Pro', sans-serif;
            font-size: ${({ theme }) => theme.fontSizes[8]};
            font-weight: 500;
            line-height: 17px;
            text-align: left;
            color: ${({ theme }) => theme.colors.black[3]};
            transition: all 100ms ease-in-out;
            opacity: 0.7;
        }
    }
    a:hover {
        .title {
            opacity: 1;
            color: ${({ theme }) => theme.colors.black[3]};
        }
    }

    .is--nav--active {
        opacity: 1;
        .nav--img__active {
            display: block;
        }
        .nav--img {
            display: none;
        }
        .title {
            opacity: 1;
            color: ${({ theme }) => theme.colors.black[3]};
        }
    }
    @media (max-width: 768px) {
        position: fixed;
        display: flex;
        bottom: 0;
        display: none;
        width: 100%;
        justify-content: space-between;
        padding: 4.5px 10px 4px;
        border: solid 0.5px #dae0ea;
        background-color: #ffffff;
        margin: 0;
        height: 60px;
        z-index: 20000000000;
        .title {
            margin-block: 0;
        }
        a {
            flex-direction: column;
            justify-content: space-around;
        }
        .nav--img,
        .nav--img__active {
            margin-right: 0 !important;
        }
    }
    @media (max-width: 414px) {
        .title {
            font-size: ${({ theme }) => theme.fontSizes[15]} !important;
        }
    }
`

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ mb }) => mb || '75px'};
    margin-top: ${({ mt }) => mt || '0px'};
    .title {
        font-family: 'Mabry Pro', sans-serif;
        padding: 0px;
        line-height: 18px;
        text-align: left;
        margin-bottom: 15px;
        font-family: 'Mabry Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        opacity: 0.6;
    }
    .referral-link {
        font-family: 'Mabry Pro', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-decoration-line: underline;
        display: inline-block;
        color: #000000;
        cursor: pointer;
        transition: all 200ms ease-in;
        :hover {
            transform: translateY(2px);
        }
    }
`

export const Text = styled.span`
    font-family: 'Mabry Pro', sans-serif;
    line-height: 24px;
    text-align: left;
    text-decoration: ${({ lineThrough }) =>
        lineThrough ? 'line-through' : 'none'};
    ${colors};
    ${fontSizes};
    ${fontWeight}
`

export const Information = styled.div`
    border-radius: 5px;
    background-color: ${({ theme, color, variation }) =>
        theme.colors[color][variation] || theme.colors[color].default};
    padding: 25px 15px 20px 15px;
    width: calc(32%- (40 / 3));
    margin: 0;
    margin-bottom: 12px;
    min-width: 279px;
    .referral-todo {
        background-color: rgba(210, 67, 57, 1);
        margin-bottom: 12px;
        border-radius: 50%;
        color: #ffffff;
        padding: 4px 6px;
        cursor: pointer;
        height: 20px;
        width: 20px;
        font-family: 'Mabry Pro', sans-serif;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
    }
    .coming-soon-pill {
        width: fit-content;
        font-family: 'Mabry Pro', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        /* identical to box height */

        color: #ffffff;
        padding: 5px 10px;
        border-radius: 40px;
        background: #000000;
    }
    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
            width: 28px;
            height: 28px;
        }
    }
    @media (max-width: 988px) {
        width: 100%;
        margin-bottom: 20px;
    }
`
export const Balance = styled.div`
    display: flex;
    align-items: flex-end;
`

export const Grid = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 988px) {
        flex-direction: column;
        .stretch {
            width: 100% !important;
        }
    }
`

export const Activity = styled.div`
    margin-top: 12px;
    margin-bottom: 10px;
    .transaction-amount {
        display: flex !important;
        flex-direction: column !important;
    }
    .detail {
        display: flex;
        align-items: flex-start;

        img {
            display: block;
            width: 36px;
            height: 36px;
            margin-right: 26px;
        }
        .more {
            display: flex;
            flex-direction: column;
            margin-left: 16px;
        }
    }
`

export const Action = styled.div`
    width: 100%;
    background-color: ${({ theme, color, variation }) =>
        theme.colors[color][variation]};
    padding: 30px 20px 20px 20px;
    border-radius: 5px;
    margin-bottom: 20px;

    a {
        text-decoration: none;
        svg {
            transition: all 300ms ease-in-out;
            .cls-1-new {
                fill: ${({ theme, color, fontvariation }) =>
                    theme.colors[color][fontvariation]};
            }
        }
    }

    a:hover {
        svg {
            transform: translateX(5px);
        }
    }
    img {
        width: 72.2px;
        height: auto;
        display: block;
    }
`

export const Withdraw = styled.span`
    margin-right: 10px;
    .cls-1-new-up {
        width: 20px;
        height: 20px;

        fill: ${({ theme, color, variation }) =>
            theme?.colors?.[color]?.[variation]};
        stroke: ${({ theme, color, variation }) =>
            theme?.colors?.[color]?.[variation]};
    }
`

export const TansparentButton = styled.button`
    border: none;
    background: none;
    box-shadow: none;
    outline: none;
    padding: 0;
`

export const NavContainer = styled.div`
    margin-top: -13px;
    .rc-tabs-top .rc-tabs-tab {
        color: ${({ theme }) => theme.colors.black[3]};
        line-height: 20px;
        text-align: center;
        padding: 7px 27.5px;
        padding-top: 0 !important;
        margin-right: 0;
        transition: all 50ms ease-in-out;
        font-family: 'Mabry Pro', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
    }
    .rc-tabs-tab:nth-child(4) {
        text-transform: uppercase;
    }
    .rc-tabs-tab-active {
        font-weight: ${({ theme }) => theme.thickness[6]} !important;
        color: ${({ theme }) => theme.colors.black[1]} !important;
    }
    .rc-tabs-ink-bar {
        height: 2px !important;
        background-color: ${({ theme }) => theme.colors.black[3]} !important;
    }
    .rc-tabs-top .rc-tabs-bar,
    .rc-tabs-top {
        border-bottom: 0px;
    }
    .rc-tabs-top .rc-tabs-nav-scroll {
        max-width: 100% !important;
        width: 100% !important;
    }
    .rc-tabs-nav {
        margin-bottom: 10px;
    }
    .slider-navigation {
        display: block;
    }
    .dropdown-navigation {
        display: none;
    }
    @media (max-width: 1024px) {
        .rc-tabs-top .rc-tabs-tab {
            padding: 10px 30px;
        }
    }
    @media (max-width: 1024px) {
        margin-top: 0;
    }
    @media (max-width: 1024px) {
        margin-top: 0;
    }
    @media (max-width: 1025px) {
        .slider-navigation {
            display: none;
        }
        .dropdown-navigation {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
    @media (max-width: 577px) {
        .rc-tabs-top .rc-tabs-tab {
            font-size: ${({ theme }) => theme.fontSizes[16]};
            padding: 5px 10px;
        }
    }
    @media (max-width: 400px) {
        .rc-tabs-top .rc-tabs-tab {
            font-size: ${({ theme }) => theme.fontSizes[15]};
            padding: 5px 10px;
        }
    }
`

export const More = styled.div`
    padding: 12px 15px;
    border-radius: 2.5px;
    border: 0;
    margin-top: 0;
    width: 100%;
    max-width: fit-content;
    margin-top: 0;
    animation: ${fadeIn} 0.5s linear;
    margin-left: 41px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    .head {
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        text-align: left;
        display: block;
        margin-bottom: 5px;
        font-family: 'Mabry Pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 114% */

        color: #000000;
    }
    .content {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #8a6c3b;

        font-family: 'Mabry Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        /* or 150% */

        color: #000000;

        opacity: 0.7;
        margin-bottom: 0;
        margin-top: 5px;
        width: 424px;
        max-width: 100%;
        a {
            color: #8a6c3b;
            text-decoration: underline;
        }
    }
    @media (max-width: 917px) {
        margin-left: 0;
        margin-top: 40px;
    }
    @media (max-width: 561px) {
        width: 100% !important ;
    }
`
export const VolumeContainer = styled.div`
    margin-top: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    select {
        min-width: 200px;
    }
    .content {
        display: flex;
        justify-content: space-between;
        width: 55%;
    }
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        .content {
            width: 100%;
        }
        select {
            width: 50%;
        }
    }
    @media (max-width: 560px) {
        .content {
            flex-direction: column;
        }
        .div--volume,
        .div--transaction {
            margin-bottom: -60px;
        }
    }
`

export const StyledSelect = styled(Select)`
    border: rgba(0, 0, 0, 0.05) !important;
    color: #000000;
    width: ${({ width }) => (width ? width : '81px')}!important;
    background: ${({ isInputSelect }) =>
        isInputSelect ? 'transparent' : 'rgba(0, 0, 0, 0.05)'};
    border-radius: 40px !important;
    padding: ${({ isInputSelect }) =>
        isInputSelect ? '0px' : '4.13px 10px'} !important;
    opacity: 1;
    max-width: 100%;
    min-height: 30.25px !important;
    position: relative;
    :focus-within {
        box-shadow: none !important;
        outline: none !important;
    }
    .react-dropdown-select-content {
        color: #000000 !important;
        font-family: 'Mabry Pro', sans-serif !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 14px !important;
        /* identical to box height */
        color: #000000;
        margin-top: 3px;
        margin-right: 5px;
        span {
            margin-top: 3px;
        }
    }
    .react-dropdown-select-clear,
    .react-dropdown-select-dropdown-handle {
        color: #000000;
        display: inline-flex;
    }
    .react-dropdown-select-option {
        border: 1px solid #000000;
    }
    .react-dropdown-select-item {
        color: #000000;
    }
    .react-dropdown-select-input {
        color: #000000;
        /* display: none; */
    }
    .react-dropdown-select-dropdown {
        position: absolute;
        left: 0;
        border: none;
        width: ${({ dropDonwWidth }) =>
            dropDonwWidth ? dropDonwWidth : '500px'};
        padding: 0;
        display: flex;
        flex-direction: column;
        border-radius: 2px;
        max-height: 300px;
        overflow: auto;
        z-index: 9;
        background: #333;
        box-shadow: none;
        color: #000000 !important;
        width: ${({ isInputSelect }) =>
            isInputSelect ? '86px' : '100%'} !important;
        background-color: #ffffff !important;
        border: 1px solid #000000 !important;
        border-radius: 5px !important;
        padding: 3px 0;
        transition: all 200ms ease-in;
    }
    .react-dropdown-select-item {
        color: #000000;
        border-bottom: none;
        font-family: 'Mabry Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        padding: ${({ isInputSelect }) =>
            isInputSelect ? '11px' : '16px 10px'};
        :hover {
            color: #000000;
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
    .react-dropdown-select-item.react-dropdown-select-item-selected,
    .react-dropdown-select-item.react-dropdown-select-item-active {
        color: #000000;
        border-bottom: none;
        background-color: #ffffff;
        font-family: 'Mabry Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        :hover {
            color: #000000;
            background-color: rgba(0, 0, 0, 0.05);
        }
        //background: #111;
        /* border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        color: #000000;
        font-weight: bold; */
    }
    .react-dropdown-select-item.react-dropdown-select-item-disabled {
        background: rgba(0, 0, 0, 0.05);
        color: #000000;
    }
`

export const StandardSelect = styled(Select)`
    border: ${({ isStateFieldErroed }) => {
        return isStateFieldErroed
            ? '1px solid #d24339'
            : 'rgba(0, 0, 0, 0.05)!important'
    }};
    color: #000000;
    width: ${({ width }) => (width ? width : '81px')} !important;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 40px !important;
    padding: 7.13px 10px !important;
    opacity: 1 !important;
    max-width: 100%;
    height: 40px;
    padding-right: 15px !important;
    position: relative;

    :focus-within {
        box-shadow: none !important;
        outline: none !important;
    }
    .react-dropdown-select-input {
        ::placeholder {
            font-family: 'Mabry Pro', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */

            color: #000000;

            opacity: 0.4;
        }
    }
    .react-dropdown-select-content {
        color: #000000 !important;
        font-family: 'Mabry Pro', sans-serif !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        /* identical to box height */
        color: #000000;
        margin-top: 3px;
        margin-right: 5px;
        max-width: calc(100% - 24px);
    }
    .react-dropdown-select-clear,
    .react-dropdown-select-dropdown-handle {
        color: #000000;
        display: inline-flex;
    }
    .react-dropdown-select-option {
        border: 1px solid #000000;
        transition: all 0.3s ease-out;
    }
    .react-dropdown-select-item {
        color: #000000;
    }
    .react-dropdown-select-input {
        color: #000000;
        display: none;
    }
    .react-dropdown-select-dropdown {
        position: absolute;
        left: 0;
        border: none;
        width: 500px;
        padding: 0;
        display: flex;
        flex-direction: column;
        border-radius: 2px;
        max-height: 300px;
        overflow: auto;
        z-index: 9;
        background: #333;
        box-shadow: none;
        color: #000000 !important;
        width: 100% !important;
        background-color: #ffffff !important;
        border: 1px solid #000000 !important;
        border-radius: 5px !important;
        padding: 3px 0;
        transition: all 200ms ease-in;
    }
    .react-dropdown-select-item {
        color: #000000;
        border-bottom: none;
        font-family: 'Mabry Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        :hover {
            color: #000000;
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
    .react-dropdown-select-item.react-dropdown-select-item-selected,
    .react-dropdown-select-item.react-dropdown-select-item-active {
        color: #000000;
        border-bottom: none;
        background-color: #ffffff;
        font-family: 'Mabry Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        :hover {
            color: #000000;
            background-color: rgba(0, 0, 0, 0.05);
        }
        //background: #111;
        /* border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        color: #000000;
        font-weight: bold; */
    }
    .react-dropdown-select-item.react-dropdown-select-item-disabled {
        background: rgba(0, 0, 0, 0.05);
        color: #000000;
    }
    width: 100% !important;
    background: #ffffff !important;
    border: ${({ isStateFieldErroed }) => {
        return isStateFieldErroed
            ? '1px solid #d24339 !important'
            : '1px solid rgba(0, 0, 0, 0.5) !important'
    }};
    border-radius: 5px !important;
    margin-bottom: 15px !important;

    ${({ searchable }) =>
        searchable &&
        css`
            .react-dropdown-select-input {
                color: #000000;
                display: inline;
                font-family: 'Mabry Pro', sans-serif;
                font-style: normal;
                font-weight: 400 !important;
                font-size: 16px;
                line-height: 20px;
                /* identical to box height, or 125% */
                color: #000000;
            }
            .react-dropdown-select-content {
                font-weight: 400 !important;
            }
        `}
`

export const Button = styled(DefaultButton)`
    padding: 10px 16px;
    .img {
        margin-right: 10px;
    }
`

export const BalanceSettingsContainer = styled.div`
    display: flex;
    align-items: flex-end;
    @media (max-width: 858px) {
        flex-direction: column;
        align-items: flex-start;
        button,
        a {
            margin: 7.5px 0 !important;
            width: 100%;
        }
        .currency-selector {
            margin-bottom: 22.25px;
        }
    }
`
