import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ backgroundColor }) =>
        backgroundColor || 'rgba(0, 0, 0, 0.5)'};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding-bottom: 20%;
`
