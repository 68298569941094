import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    transform: translateY(5px);
    opacity:0;
  }

  to {
   transform: translateY(0px);
   opacity:1;
  }
`
export const Container = styled.form`
    width: 100%;
    background: #ffffff;
    max-width: 329px;
    border-radius: 5px;
    padding: 30px 17px;
    display: flex;
    flex-direction: column;
    align-items: inherit;
    margin: auto;
    animation: ${fadeIn} 0.005s linear;
    transition: all 200ms linear;
    border: 1px solid #000000;
    transition: all 300ms ease-in-out;
    h1 {
        font-family: 'Mabry Pro', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        /* identical to box height */

        text-align: center;
        color: #000000;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    p {
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #545969;
        margin-bottom: 30px;
        margin-top: 0;
    }
    .payment-method-3 {
        .react-dropdown-select-item {
            :nth-child(3) {
                opacity: 0.5;
            }
        }
    }
    .payment-method-2 {
        .react-dropdown-select-item {
            :nth-child(2) {
                opacity: 0.5;
            }
        }
    }
    .referral-link-copy {
        position: relative;
        ::after {
            content: '';
            color: #ffffff;
            background-color: #000000;
            height: auto;
            width: 50px;
            left: calc(100% + 12px);
            top: 0;
            position: absolute;
            font-family: 'Mabry Pro', sans-serif;
            line-height: 0;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            padding: 12px;
            text-align: center;
            border-radius: 5px;
            opacity: 0;
            padding-left: 5px;
            transition: all 50ms ease-in-out;
        }
        ::before {
            content: '';
            background-color: #000;
            padding: 7px;
            position: absolute;
            left: calc(100% + 6px);
            top: 5px;
            transform: rotate(45deg);
            opacity: 0;
            transition: all 50ms ease-in-out;
        }
        :hover::after {
            opacity: 0.9;
            content: 'copy';
        }
        :hover::before {
            opacity: 0.9;
        }
        :active::after {
            opacity: 1;
            content: 'copied!';
        }
    }
`
export const FormContainer = styled.form`
    width: 100%;
    background: #ffffff;
    max-width: 329px;
    border-radius: 10px;
    padding: 30px 17px;
    display: flex;
    flex-direction: column;
    align-items: inherit;
    margin: auto;
    animation: ${fadeIn} 0.005s linear;
    transition: all 200ms linear;
    border: 1px solid #000000;
    transition: all 300ms ease-in-out;
    h1 {
        font-family: 'Mabry Pro', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        /* identical to box height */

        text-align: center;
        color: #000000;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    p {
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #545969;
        margin-bottom: 30px;
        margin-top: 0;
    }
`

export const More = styled.div`
    padding: 11px 15px;
    border-radius: 2.5px;
    border: solid 0.5px rgba(138, 108, 59, 0.15);
    background-color: #fcf8e3;
    margin-top: 26px;
    .head {
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        text-align: left;
        color: #8a6c3b;
        display: block;
        margin-bottom: 5px;
    }
    .content {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #8a6c3b;
        a {
            color: #8a6c3b;
            text-decoration: underline;
        }
    }
`
export const ContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 329px;
    margin: 0 auto;
    width: 100%;
`

export const Account = styled.div`
    border-bottom: solid 0.5px rgba(84, 89, 105, 0.08);
    padding: 10px 0;
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
`
