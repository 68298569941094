import React, { memo } from 'react'
import Modal from '../../modal'
import { Button } from '../styles/elements'
import { ContainerColumn, Container } from '../Balances/Payouts/elements'
import { ReactComponent as HoverBoard } from '../assets/images/newUpdates.svg'
import {
    withServiceWorkerUpdater,
    ServiceWorkerUpdaterProps,
} from '@3m1/service-worker-updater'

const headerStyle: any = {
    fontFamily: 'Mabry Pro',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#000000',
}
const bodyStyle: any = {
    fontFamily: 'Mabry Pro',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#000000',
    maxWidth: 276,
    marginBottom: 0,
}
const NewUpdates = ({
    onLoadNewServiceWorkerAccept,
}: {
    onLoadNewServiceWorkerAccept: () => void
}) => {
    const handlePropagation = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    return (
        <ContainerColumn onClick={handlePropagation}>
            <Container>
                <HoverBoard
                    style={{
                        display: 'block',
                        margin: 'auto',
                        marginBottom: 15,
                    }}
                />

                <h1 style={headerStyle}>New updates available</h1>
                <p style={bodyStyle}>
                    Reload to see the new features we have for you!
                </p>
                <Button
                    style={{
                        margin: 'auto',
                        maxWidth: 'fit-content',
                        marginTop: 15,
                    }}
                    onClick={onLoadNewServiceWorkerAccept}
                >
                    Reload to update
                </Button>
            </Container>
        </ContainerColumn>
    )
}

const NewUpdatesModal = withServiceWorkerUpdater(
    (props: ServiceWorkerUpdaterProps) => {
        const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props
        return newServiceWorkerDetected ? (
            <Modal style={{ zIndex: 10000000 }}>
                <NewUpdates
                    onLoadNewServiceWorkerAccept={onLoadNewServiceWorkerAccept}
                />
            </Modal>
        ) : null
    }
)

export default memo(NewUpdatesModal)
